<template>
  <div>
    <div v-if="user_type == 'agent'">
      <b-card
        no-body
        style="height: 150px"
        class="d-flex justify-content-center align-items-center"
      >
        <h3>"Privilege access only to Aliff Franchise."</h3>
      </b-card>
    </div>

    <b-card v-if="user_type !== 'agent' && accessToken && isTeamLeader">
      <b-card v-if="showFiltersMembers" class="mb-0">
        <div class="d-flex justify-content-between mb-1">
          <h3>Filters</h3>
          <div class="d-flex" style="gap: 10px">
            <div
              style="height: 30px"
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                cursor-pointer
                px-2
                rounded-sm
                shadow-sm
                bg-primary
                text-white
              "
              @click="resetFilters"
            >
              Reset Filters
            </div>
            <div
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                rounded-pill
                cursor-pointer
                shadow-sm
                bg-danger
              "
              style="width: 30px; height: 30px; margin-right: 5px"
              @click="showFiltersMembers = !showFiltersMembers"
            >
              <feather-icon icon="XIcon" style="stroke: #fff" />
            </div>
          </div>
        </div>

        <b-row style="gap: 10px 0">
          <b-col cols="3">
            <v-select
              v-model="filters_members.source_id"
              placeholder="Source"
              clearable
              :options="sources"
              label="name"
              :reduce="(item) => item.ls_id"
              @input="onFilterChangeMembers"
            />
          </b-col>

         
          <b-col cols="3">
            <date-range-picker
              @update="handleDateUpdateMembers"
              :locale-data="locale"
              :opens="opens"
              :autoApply="autoApply"
              :maxDate="maxDate"
              v-model="dateRangeMembers"
            >
            </date-range-picker>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="" style="">
        <div
          class="mt-2 mb-2 d-flex justify-content-between"
          v-if="isTeamLeader"
        >
          <div>
            <span class="h3 font-weight-bold">Members Wise</span>
            <span class="font-weight-bold h5">
              (Total
              <span class="text-success">{{
                total_lead_count_by_members
              }}</span>
              leads)
            </span>
          </div>
          <div class="d-flex" style="gap: 5px">
            <div
              v-if="!showFiltersMembers"
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                rounded-pill
                cursor-pointer
                shadow-sm
                bg-primary
              "
              style="width: 30px; height: 30px; margin-right: 5px"
              @click="showFiltersMembers = true"
            >
              <feather-icon icon="FilterIcon" style="stroke: #fff" />
            </div>
          </div>
        </div>
        <b-table
          style="max-height: 60vh; cursor: pointer"
          :fields="leader_table_columns"
          :items="lead_counts_by_members"
          responsive
          sticky-header
          show-empty
          empty-text="No Leads Available"
          :busy="isLoading"
          v-if="isTeamLeader"
          foot-clone
          class="custom-table"
        >
          <template #cell(member_name)="data">
            <div style="width: max-content">
              {{ data.item.member_name }}
            </div>
          </template>
          <template #cell(untouched_leads)="data">
            <div
              style="width: max-content"
              @click="
                () => {
                  handleClickOnCount(data.item.member_id, 0);
                }
              "
            >
              {{
                getFrommatedLeadStageWithCount(data.item.stages, "Untouched")
                  .lead_count
              }}
            </div>
          </template>
          <template #cell(hot_leads)="data">
            <div
              style="width: max-content"
              @click="
                () => {
                  const { stage_id } = getFrommatedLeadStageWithCount(
                    data.item.stages,
                    'Hot'
                  );
                  handleClickOnCount(data.item.member_id, stage_id);
                }
              "
            >
              {{
                getFrommatedLeadStageWithCount(data.item.stages, "Hot")
                  .lead_count
              }}
            </div>
          </template>
          <template #cell(warm_leads)="data">
            <div
              style="width: max-content"
              @click="
                () => {
                  const { stage_id } = getFrommatedLeadStageWithCount(
                    data.item.stages,
                    'Warm'
                  );
                  handleClickOnCount(data.item.member_id, stage_id);
                }
              "
            >
              {{
                getFrommatedLeadStageWithCount(data.item.stages, "Warm")
                  .lead_count
              }}
            </div>
          </template>
          <template #cell(cold_leads)="data">
            <div
              style="width: max-content"
              @click="
                () => {
                  const { stage_id } = getFrommatedLeadStageWithCount(
                    data.item.stages,
                    'Cold'
                  );
                  handleClickOnCount(data.item.member_id, stage_id);
                }
              "
            >
              {{
                getFrommatedLeadStageWithCount(data.item.stages, "Cold")
                  .lead_count
              }}
            </div>
          </template>
          <template #cell(failed_leads)="data">
            <div
              style="width: max-content"
              @click="
                () => {
                  const { stage_id } = getFrommatedLeadStageWithCount(
                    data.item.stages,
                    'Failed'
                  );
                  handleClickOnCount(data.item.member_id, stage_id);
                }
              "
            >
              {{
                getFrommatedLeadStageWithCount(data.item.stages, "Failed")
                  .lead_count
              }}
            </div>
          </template>
          <template #cell(counseled_leads)="data">
            <div
              style="width: max-content"
              @click="
                () => {
                  const { stage_id } = getFrommatedLeadStageWithCount(
                    data.item.stages,
                    'Counseled'
                  );
                  handleClickOnCount(data.item.member_id, stage_id);
                }
              "
            >
              {{
                getFrommatedLeadStageWithCount(data.item.stages, "Counseled")
                  .lead_count
              }}
            </div>
          </template>
          <template #cell(completed_leads)="data">
            <div
              style="width: max-content"
              @click="
                () => {
                  const { stage_id } = getFrommatedLeadStageWithCount(
                    data.item.stages,
                    'Completed'
                  );
                  handleClickOnCount(data.item.member_id, stage_id);
                }
              "
            >
              {{
                getFrommatedLeadStageWithCount(data.item.stages, "Completed")
                  .lead_count
              }}
            </div>
          </template>

          <template #foot(untouched_leads)="data">
            <span class="text-danger">{{
              stageCountersByMembers.untouched
            }}</span>
          </template>
          <template #foot(hot_leads)="data">
            <span class="text-success">{{ stageCountersByMembers.hot }}</span>
          </template>
          <template #foot(warm_leads)="data">
            <span class="text-success">{{ stageCountersByMembers.warm }}</span>
          </template>
          <template #foot(cold_leads)="data">
            <span class="text-success">{{ stageCountersByMembers.cold }}</span>
          </template>
          <template #foot(failed_leads)="data">
            <span class="text-success">{{
              stageCountersByMembers.failed
            }}</span>
          </template>
          <template #foot(counseled_leads)="data">
            <span class="text-success">{{
              stageCountersByMembers.counseled
            }}</span>
          </template>
          <template #foot(completed_leads)="data">
            <span class="text-success">{{
              stageCountersByMembers.completed
            }}</span>
          </template>
          <template #foot(total_leads)="data">
            <span class="text-success"></span>
          </template>
        </b-table>
        <div class="loader" v-if="isLoading">
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </div>
      </b-card>
    </b-card>

    <b-card v-if="user_type !== 'agent' && accessToken">
      <!-- Filters -->
      <b-card v-if="showFilters" class="mb-0">
        <div class="d-flex justify-content-between mb-1">
          <h3>Filters</h3>
          <div class="d-flex" style="gap: 10px">
            <div
              style="height: 30px"
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                cursor-pointer
                px-2
                rounded-sm
                shadow-sm
                bg-primary
                text-white
              "
              @click="resetFilters"
            >
              Reset Filters
            </div>
            <div
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                rounded-pill
                cursor-pointer
                shadow-sm
                bg-danger
              "
              style="width: 30px; height: 30px; margin-right: 5px"
              @click="showFilters = !showFilters"
            >
              <feather-icon icon="XIcon" style="stroke: #fff" />
            </div>
          </div>
        </div>

        <b-row style="gap: 10px 0">
          <!-- Lead Source -->
          <b-col cols="3">
            <v-select
              v-model="filters.source_id"
              placeholder="Source"
              clearable
              :options="sources"
              label="name"
              :reduce="(item) => item.ls_id"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Member Filter -->
          <b-col cols="3" v-if="isTeamLeader">
            <v-select
              clearable
              placeholder="Lead Owner"
              v-model="filters.member_id"
              :options="team_members"
              label="user_first_name"
              :reduce="(item) => item.user_id"
              @input="onFilterChange"
            >
              <template slot="option" slot-scope="option">
                {{ option.user_first_name }} {{ option.user_last_name }}
              </template>

              <template slot="selected-option" slot-scope="option">
                {{ option.user_first_name }} {{ option.user_last_name }}
              </template>
            </v-select>
          </b-col>

       

          <b-col
            cols="3"
            v-for="cfv in lc_field_values"
            :key="'cfv_' + cfv.lcf_id"
          >
            <v-select
              v-model="category_wise_filters[cfv.lcf_id]"
              :placeholder="cfv.name"
              clearable
              :options="cfv.values"
              @input="onFilterChange"
            />
          </b-col>

          <b-col cols="3">
           
            <date-range-picker
              @update="handleDateUpdate"
              :locale-data="locale"
              :opens="opens"
              :autoApply="autoApply"
              :maxDate="maxDate"
              v-model="dateRange"
            >
            </date-range-picker>
          </b-col>
        </b-row>
      </b-card>

      <!-- Table -->
      <b-card
        style="overflow-y: scroll; scrollbar-width: none"
        id="university-list"
        no-body
      >
        <b-card>
          <div v-if="show_bulk_actions" class="w-100">
            <div
              class="
                border-top border-bottom
                d-flex
                align-items-center
                justify-content-center
                py-1
                mb-1
              "
            >
              <b-button
                variant="primary"
                style="width: fit-content"
                @click="showBulkEmailModal"
              >
                Send Bulk Mail
              </b-button>
            </div>
          </div>
          <b-col
            cols="12"
            class="
              d-flex
              align-items-center
              justify-content-between justify-content-sm-between
            "
          >
            <div class="mt-2 mb-2">
              <span class="h3 font-weight-bold">Source Wise</span>
              <span class="font-weight-bold h5">
                (Total
                <span class="text-success">{{ total_lead_count }}</span>
                leads)
              </span>
            </div>
          
            <div class="d-flex" style="gap: 5px">
              
              <div
                v-if="!showFilters"
                class="
                  border
                  d-flex
                  justify-content-center
                  align-items-center
                  rounded-pill
                  cursor-pointer
                  shadow-sm
                  bg-primary
                "
                style="width: 30px; height: 30px; margin-right: 5px"
                @click="showFilters = true"
              >
                <feather-icon icon="FilterIcon" style="stroke: #fff" />
              </div>
            </div>
          </b-col>

          <b-table
            v-if="!isLoading"
            style="max-height: 60vh; cursor: pointer"
            :fields="new_table_columns"
            :items="lead_counts"
            responsive
            sticky-header
            show-empty
            empty-text="No Leads Available"
            :busy="isLoading"
            foot-clone
            class="custom-table"
          >
            <!-- Set Minimum Width of Each Column -->

            <template #cell(source_name)="data">
              <div style="width: max-content">
                {{ data.item.source_name }}
              </div>
            </template>

            <template #cell(untouched_leads)="data">
              <div
                style="width: max-content"
                @click="
                  () => {
                    const { stage_id } = getFrommatedLeadStageWithCount(
                      data.item.stages,
                      'Untouched',                
                    );

                    handleClickOnCountSourceWise(data.item.ls_id, 0);
                  }
                "
              >
                {{
                  getFrommatedLeadStageWithCount(data.item.stages, "Untouched")
                    .lead_count
                }}
              </div>
            </template>

            <template #cell(hot_leads)="data">
              <div
                style="width: max-content"
                @click="
                  () => {
                    const { stage_id, lead_count } = getFrommatedLeadStageWithCount(
                      data.item.stages,
                      'Hot'
                    );
                    if(lead_count > 0) handleClickOnCountSourceWise(data.item.ls_id, stage_id);
                  }
                "
              >
                {{
                  getFrommatedLeadStageWithCount(data.item.stages, "Hot")
                    .lead_count
                }}
              </div>
            </template>
            <template #cell(warm_leads)="data">
              <div
                style="width: max-content"
                @click="
                  () => {
                    const { stage_id, lead_count } = getFrommatedLeadStageWithCount(
                      data.item.stages,
                      'Warm'
                    );
                    if(lead_count > 0) handleClickOnCountSourceWise(data.item.ls_id, stage_id);
                  }
                "
              >
                {{
                  getFrommatedLeadStageWithCount(data.item.stages, "Warm")
                    .lead_count
                }}
              </div>
            </template>
            <template #cell(cold_leads)="data">
              <div
                style="width: max-content"
                @click="
                  () => {
                    const { stage_id, lead_count } = getFrommatedLeadStageWithCount(
                      data.item.stages,
                      'Cold'
                    );
                    if(lead_count > 0) handleClickOnCountSourceWise(data.item.ls_id, stage_id);
                  }
                "
              >
                {{
                  getFrommatedLeadStageWithCount(data.item.stages, "Cold")
                    .lead_count
                }}
              </div>
            </template>
            <template #cell(failed_leads)="data">
              <div
                style="width: max-content"
                @click="
                  () => {
                    const { stage_id, lead_count } = getFrommatedLeadStageWithCount(
                      data.item.stages,
                      'Failed'
                    );
                    if(lead_count > 0) handleClickOnCountSourceWise(data.item.ls_id, stage_id);
                  }
                "
              >
                {{
                  getFrommatedLeadStageWithCount(data.item.stages, "Failed")
                    .lead_count
                }}
              </div>
            </template>
            <template #cell(counseled_leads)="data">
              <div
                style="width: max-content"
                @click="
                  () => {
                    const { stage_id, lead_count } = getFrommatedLeadStageWithCount(
                      data.item.stages,
                      'Counseled'
                    );
                    if(lead_count > 0) handleClickOnCountSourceWise(data.item.ls_id, stage_id);
                  }
                "
              >
                {{
                  getFrommatedLeadStageWithCount(data.item.stages, "Counseled")
                    .lead_count
                }}
              </div>
            </template>
            <template #cell(completed_leads)="data">
              <div
                style="width: max-content"
                @click="
                  () => {
                    const { stage_id, lead_count } = getFrommatedLeadStageWithCount(
                      data.item.stages,
                      'Completed'
                    );
                    if(lead_count > 0) handleClickOnCountSourceWise(data.item.ls_id, stage_id);
                  }
                "
              >
                {{
                  getFrommatedLeadStageWithCount(data.item.stages, "Completed")
                    .lead_count
                }}
              </div>
            </template>

            <template #foot(untouched_leads)="data">
              <span class="text-danger">{{ stageCounters.untouched }}</span>
            </template>
            <template #foot(hot_leads)="data">
              <span class="text-success">{{ stageCounters.hot }}</span>
            </template>
            <template #foot(warm_leads)="data">
              <span class="text-success">{{ stageCounters.warm }}</span>
            </template>
            <template #foot(cold_leads)="data">
              <span class="text-success">{{ stageCounters.cold }}</span>
            </template>
            <template #foot(failed_leads)="data">
              <span class="text-success">{{ stageCounters.failed }}</span>
            </template>
            <template #foot(counseled_leads)="data">
              <span class="text-success">{{ stageCounters.counseled }}</span>
            </template>
            <template #foot(completed_leads)="data">
              <span class="text-success">{{ stageCounters.completed }}</span>
            </template>
            <template #foot(total_leads)="data">
              <span class="text-success"></span>
            </template>
          </b-table>
          <div class="loader" v-if="isLoading">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </b-card>
      </b-card>
    </b-card>

   

    <div v-if="user_type !== 'agent' && accessTokenFailed">
      <b-card
        no-body
        style="height: 150px"
        class="d-flex justify-content-center align-items-center"
      >
        <h3>"Please Contact Adminstrator For Activating Your Leads."</h3>
      </b-card>
    </div>

    <!-- <div v-if="!isLeader">
      <b-card
        no-body
        style="height: 150px"
        class="d-flex justify-content-center align-items-center"
      >
        <h3>Only For Team Leaders</h3>
        <b-button @click="navigateToMyLeads">Go to My Leads</b-button>
      </b-card>
    </div> -->
  </div>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import CommonServices from "@/apiServices/CommonServices";
import CounselorServices from "@/apiServices/CounselorServices";
import LmsServices from "@/apiServices/LmsServices";
import moment from "@/utils/moment";
import SendEmailModal from "@/views/components/MyLeadComponents/SendEmailModal.vue";
import { email, required } from "@validations";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import infiniteScroll from "vue-infinite-scroll";
import { quillEditor } from "vue-quill-editor";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DateRangePicker,
    BCard,
    BTable,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    BPagination,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    BModal,
    BFormTextarea,
    BForm,
    SendEmailModal,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BFormGroup,
    ToastificationContentVue,
    draggable,
    BTooltip,
    quillEditor,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
    user_email() {
      return this.$store.getters["user/getUserDetails"].user_email;
    },
    show_bulk_actions() {
      return this.allLeads.filter((lead) => lead.is_selected).length > 0;
    },
    user_id() {
      return this.$store.getters["user/getUserDetails"].user_id;
    }
  },

  directives: {
    BTooltip,
  },

  data() {
    return {
      email_signature: "",
      isLeader: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link", "image"],
          ],
        },
        placeholder: "",
      },
      default_order: [
        { label: "Country", position: 2 },
        { label: "Discipline", position: 3 },
        { label: "Level", position: 4 },
        { label: "Course", position: 5 },
      ],
      isTeamLeader: false,
      accessToken: null,
      accessTokenFailed: false,
      lmsApi: null,
      isCustomDateRange: false,

      showFilters: true,
      showFiltersMembers: false,
      isLoading: false,
      passwordFieldType: "password",

      filters: {
        s_id: null,
        ss_id: null,
        state_id: null,
        city_id: null,
        search: null,
        registration_date: null,
        category_id: null,
        follow_up_date: null,
        activity_date: null,
        source_id: null,
        member_id: null,
        m_id: null,
        c_id: null,
      },
      filters_members: {
        s_id: null,
        ss_id: null,
        state_id: null,
        city_id: null,
        search: null,
        registration_date: null,
        category_id: null,
        follow_up_date: null,
        activity_date: null,
        source_id: null,
        member_id: null,
        m_id: null,
        c_id: null,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dateRangeMembers: {
        startDate: null,
        endDate: null,
      },
      lc_field_values: [],

      follow_up_details: {
        stage_id: null,
        sub_stage_id: null,
        fu_remark: null,
        date: null,
        time: null,
      },

      student_form: {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        mobile: null,
      },

      showCreateStudentModal: false,
      showSignatureModal: false,

      emailDetails: {
        emailModalOpen: false,
        email: "",
      },

      bulkEmailDetails: {
        emailModalOpen: false,
        email: "",
      },

      // Table
      allLeads: [],
      selected_lead: null,
      tableColumns: [
        {
          key: "name",
          label: "Name",
          sortable: false,
          stickyColumn: true,
          show: true,
        },
        { key: "mobile", label: "Mobile", sortable: false, show: true },

        {
          key: "follow_up_remark",
          label: "Follow Up Remark",
          sortable: false,
          show: true,
        },
        { key: "sub_stage", label: "Sub Stage", sortable: false, show: true },
        { key: "email", label: "Email", sortable: false, show: true },
        // { key: "state", label: "State", sortable: false, show: true },
        { key: "city", label: "City", sortable: false, show: true },
        { key: "source", label: "Source", sortable: false, show: true },
        { key: "medium", label: "Medium", sortable: false, show: false },
        { key: "campaign", label: "Campaign", sortable: false, show: false },
        { key: "category", label: "Category", sortable: false, show: true },
        {
          key: "registration_date",
          label: "Registration Date",
          sortable: false,
          show: true,
        },
        // { key: "stage", label: "Stage", sortable: false, show: true },
        {
          key: "follow_up_date",
          label: "Follow Up Date",
          sortable: false,
          show: true,
        },

        { key: "remark", label: "Remarks", sortable: false, show: false },
      ],

      category_wise_filters: {},

      staticColumns: [],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
        items_count: 0,
      },
      busy: false,

      // Masters
      states: [],
      cities: [],
      stages: [],
      sub_stages: [],
      categories: [],
      category_fields: [],
      sources: [],
      team_members: [],
      mediums: [],
      campaigns: [],
      stageCounters: {},
      stageCountersByMembers: {},

      required,
      email,

      debouce: null,
      selectedDateRange: null,
      dateOptions: [
        { label: "Last 7 Days", value: "7" },
        { label: "Last 15 Days", value: "15" },
        { label: "Last 1 Month", value: "30" },
        { label: "Custom Date Range", value: "" },
      ],

      selected_lead_ids: [],
      selectable_lead_ids: [],
      select_all: false,
      new_table_columns: [
        {
          key: "source_name",
          label: "Source",
          sortable: false,
          stickyColumn: true,
          show: true,
        },
        {
          key: "total_leads",
          label: "Total",
          sortable: false,
          stickyColumn: true,
          show: true,
        },
        {
          key: "untouched_leads",
          label: "Untouched",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "warm_leads",
          label: "Warm",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "hot_leads",
          label: "Hot",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "cold_leads",
          label: "Cold",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "failed_leads",
          label: "Failed",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "counseled_leads",
          label: "Counseled",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "completed_leads",
          label: "Completed",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
      ],
      leader_table_columns: [
        {
          key: "member_name",
          label: "Members",
          sortable: false,
          stickyColumn: true,
          show: true,
        },
        {
          key: "total_leads",
          label: "Total",
          sortable: false,
          stickyColumn: true,
          show: true,
        },
        {
          key: "untouched_leads",
          label: "Untouched",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "warm_leads",
          label: "Warm",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "hot_leads",
          label: "Hot",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "cold_leads",
          label: "Cold",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "failed_leads",
          label: "Failed",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "counseled_leads",
          label: "Counseled",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
        {
          key: "completed_leads",
          label: "Completed",
          sortable: false,
          stickyColumn: true,
          show: true,
          variant: "white",
        },
      ],
      lead_counts: [],
      lead_counts_by_members: [],

      total_lead_count: null,
      total_lead_count_by_members: null,

      opens: "right",
      locale: { firstDay: 1, format: "dd-mm-yyyy" },
      autoApply: true,
      maxDate: new Date().toISOString().split(" ")[0],
    };
  },
  methods: {

    handleClickOnCount(member_id, stage_id) {
      const updatedFilters = this.filters;
      updatedFilters.member_id = member_id;
      updatedFilters.s_id = stage_id;
      localStorage.setItem("filters", JSON.stringify(updatedFilters));
      this.$emit("applyFilters");      
    },

    handleClickOnTotalCount(stage_id) {
      this.resetFilters();
      const updatedFilters = this.filters;
      updatedFilters.s_id = stage_id;
      localStorage.setItem("filters", JSON.stringify(updatedFilters));
      this.$emit("applyFilters");
      
    },
    handleClickOnCountSourceWise(source_id, stage_id) {
      const updatedFilters = this.filters;
      updatedFilters.source_id = source_id;
      updatedFilters.s_id = stage_id;
      updatedFilters.member_id = this.filters.member_id;
      localStorage.setItem("filters", JSON.stringify(updatedFilters));
      this.$emit("applyFilters");
      
    },
    getFrommatedLeadStageWithCount(stages, key) {
      const stage = stages.find((s) => s.stage_name === key);
      return {
        lead_count: stage?.lead_count || 0,
        stage_id: stage?.stage_id || stage?.s_id || null,
        
      };
    },

    handleDateUpdate(value) {
      const formattedStartDate = moment(value.startDate).format("DD-MM-YY");
      const formattedEndDate = moment(value.endDate).format("DD-MM-YY");
      this.filters.registration_date = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };
      this.onFilterChange();
    },
    handleDateUpdateMembers(value) {
      const formattedStartDate = moment(value.startDate).format("DD-MM-YY");
      const formattedEndDate = moment(value.endDate).format("DD-MM-YY");
      this.filters_members.registration_date = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };
      this.onFilterChangeMembers();
    },
    onFilterChange() {
      // this.pagination.currentPage = 1;
      // console.log(this.debouce);

      if (this.debouce) {
        clearTimeout(this.debouce);
        this.debouce = null;
      }
      // this.saveFilters();
      this.debouce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },
    onFilterChangeMembers() {
      if (this.debouce) {
        clearTimeout(this.debouce);
        this.debouce = null;
      }
      // this.saveFiltersMembers();
      this.debouce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },

    onChange() {
      this.$nextTick(() => {
        this.getLeadCounts();
        this.getLeadCountsByMembers();
      });
    },

    async getLMSAccess() {
      try {
        this.isLoading = true;
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);

        // this.getAllLeadStages();
        this.getAllLeadSources();
        this.getAllTeamMembers();
        this.getLeadCounts();
        this.getLeadCountsByMembers();

      
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },
    async getAllLeadSources() {
      try {
        const res = await this.lmsApi.getAllLeadSources();
        this.sources = res.data.data;
      } catch (error) {
        console.error("Could Not Get Lead Sources");
      }
    },

    async getAllTeamMembers() {
      try {
        const res = await this.lmsApi.getAllTeamMembers();
        this.team_members = res.data.data;
        this.filters = { ...this.filters, member_id: this.team_members[0].user_id };
      } catch (error) {
        console.error("Could Not Get Team Members");
      }
    },

    async getLeadCounts() {
      // this.isLoading = true;
      try {
        const response = await this.lmsApi.getAllLeadsCountWithSouce({
          ...this.filters,
          registration_date:
            this.selectedDateRange?.value || this.filters.registration_date,
        });
        if (response.data.status) {
          this.lead_counts = response.data.data.data;
          this.total_lead_count = response.data.data.total;
          this.calculateStageTotals();
        }
      } catch (error) {
        console.error(`Error in getLeadCounts `, error);
      } finally {
        this.isLoading = false;
      }
    },
    calculateStageTotals() {
      this.stageCounters = {
        untouched: 0,
        warm: 0,
        hot: 0,
        cold: 0,
        failed: 0,
        counseled: 0,
        completed: 0,
      };

      this.lead_counts.forEach((lead) => {
        lead.stages.forEach((stage) => {
          switch (stage.stage_name) {
            case "Untouched":
              this.stageCounters.untouched += stage.lead_count;
              break;
            case "Warm":
              this.stageCounters.warm += stage.lead_count;
              break;
            case "Hot":
              this.stageCounters.hot += stage.lead_count;
              break;
            case "Cold":
              this.stageCounters.cold += stage.lead_count;
              break;
            case "Failed":
              this.stageCounters.failed += stage.lead_count;
              break;
            case "Counseled":
              this.stageCounters.counseled += stage.lead_count;
              break;
            case "Completed":
              this.stageCounters.completed += stage.lead_count;
              break;
          }
        });
      });
    },

    calculateStageTotalsByMembers() {
      this.stageCountersByMembers = {
        untouched: 0,
        warm: 0,
        hot: 0,
        cold: 0,
        failed: 0,
        counseled: 0,
        completed: 0,
      };

      this.lead_counts_by_members.forEach((lead) => {
        lead.stages.forEach((stage) => {
          switch (stage.stage_name) {
            case "Untouched":
              this.stageCountersByMembers.untouched += stage.lead_count;
              break;
            case "Warm":
              this.stageCountersByMembers.warm += stage.lead_count;
              break;
            case "Hot":
              this.stageCountersByMembers.hot += stage.lead_count;
              break;
            case "Cold":
              this.stageCountersByMembers.cold += stage.lead_count;
              break;
            case "Failed":
              this.stageCountersByMembers.failed += stage.lead_count;
              break;
            case "Counseled":
              this.stageCountersByMembers.counseled += stage.lead_count;
              break;
            case "Completed":
              this.stageCountersByMembers.completed += stage.lead_count;
              break;
          }
        });
      });
    },
    async getLeadCountsByMembers() {
      try {
        const response = await this.lmsApi.getAllLeadCountByMembers({
          ...this.filters_members,
          // registration_date: this.selectedDateRange.value,
        });
        if (response.data.status) {
          this.lead_counts_by_members = response.data.data.data;
          this.total_lead_count_by_members = response.data.data.total;
          if (response.data.data.isLeader) {
          this.isTeamLeader = true;
        }
          this.calculateStageTotalsByMembers();
        }
      } catch (error) {
        console.error(`Error in getLeadCounts `, error);
      }
    },
    resetFilters(){
      console.log('check')
    },
   

    moment,
  },

  beforeMount() {
    let filters = localStorage.getItem("filters");
    if (filters) {
      this.filters = JSON.parse(filters);
    } else {
      localStorage.setItem("filters", JSON.stringify(this.filters));
    }

    this.getLMSAccess();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
<style>
[dir="ltr"] .dropdown-menu[x-placement="right-start"] {
  margin-left: 3rem;
}

.ql-editor {
  height: 300px;
}

.mydiv {
  border-bottom: 1px solid blue;
  padding-bottom: 10px;
}
.custom-table tfoot {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid #ddd;
}
.card-body {
  padding: 0.8rem 2rem;
}
</style>

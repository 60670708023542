<template>
  <div>
    <b-tabs v-model="activeTab" @input="onTabChange">
      <b-tab title="Lead List" ref="leadList">
        <MyLeads :key="leadListKey"/>
      </b-tab>
      <b-tab title="Lead Chart">
        <LeadChart @applyFilters="changeTab(0)" :key="leadListKey" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import LeadChart from "./LeadChart.vue";
import MyLeads from "./MyLeads.vue";

export default {
  components: {
    BTabs,
    BTab,
    LeadChart,
    MyLeads
  },

  data() {
    return {
      activeTab: 0,
      leadListKey: 0,
    };
  },

  methods: {
    
    changeTab(tab) {
      this.activeTab = tab
    },
    onTabChange(newTab) {
      if (newTab === 0) {
        this.leadListKey += 1;
      }else{
        localStorage.removeItem("filters");
        this.leadListKey += 1;
      }
    },
  },

  beforeMount() {
    const tab = this.$route.query.activeTab;
    if (tab) {
      this.activeTab = tab;
    }
  }

  
};
</script>
